import Article from './resources/Article';
import PrivateArticle from './resources/PrivateArticle';
import Ingredient from './resources/Ingredient';
import IngredientCategory from './resources/IngredientCategory';
import Recipe from './resources/Recipe';
import RecipeCategory from './resources/RecipeCategory';
import RecipeLevel from './resources/RecipeLevel';
import User from './resources/User';
import DietPlan from './resources/DietPlan';
import DietType from './resources/DietType';
// import Menu from './resources/Menu';
import Meal from './resources/Meal';
import CommentRecipe from './resources/CommentRecipe';
import EventRecipe from './resources/EventRecipe';
import { ResourceScreenType } from 'm6BoBuilder';
import PromoCode from './resources/PromoCode';
import PromotionalCommunication from './resources/PromotionalCommunication';
import PrivateFooterBanner from './resources/PrivateFooterBanner';
//import RecipeScreen from '../screens/RecipeScreen';
import UserEditScreen from '../screens/UserEditScreen';
import Season from './resources/Season';
import { ROLES } from '../constants';
import Objective from './resources/Objective';
import PromoCodeGroup from './resources/PromoCodeGroup';
import PromotionalMedia from './resources/PromotionalMedia';
import PromotionalNewsletter from './resources/PromotionalNewsletter';
import PrivatePromoBlock from './resources/PrivatePromoBlock';
import RecipeAutoPromo from './resources/RecipeAutoPromo';
import PrivatePromoPopin from './resources/PrivatePromoPopin';
import ArticleCategory from './resources/ArticleCategory';
import PrivateArticleCategory from './resources/PrivateArticleCategory';
import ArticleAuthor from './resources/ArticleAuthor';
import Review from './resources/Review';
import RecipePublicCategory from './resources/RecipePublicCategory';
import BodyParts from './resources/BodyParts';
import PublicPromoPopin from './resources/PublicPromoPopin';
import Feature from './resources/Feature';
import Tag from './resources/Tag';

const screens: ResourceScreenType[] = [
    {
        resource: Article,
        accessControl: {
            list: [ROLES.ROLE_ADMIN],
            create: [ROLES.ROLE_ADMIN],
            edit: [ROLES.ROLE_ADMIN],
            delete: [ROLES.ROLE_ADMIN],
        },
    },
    {
        resource: PrivateArticle,
        accessControl: {
            list: [ROLES.ROLE_ADMIN],
            create: [ROLES.ROLE_ADMIN],
            edit: [ROLES.ROLE_ADMIN],
            delete: [ROLES.ROLE_ADMIN],
        },
    },
    {
        resource: ArticleCategory,
        accessControl: {
            list: [ROLES.ROLE_ADMIN],
            create: [ROLES.ROLE_ADMIN],
            edit: [ROLES.ROLE_ADMIN],
            delete: [ROLES.ROLE_ADMIN],
        },
    },
    {
        resource: PrivateArticleCategory,
        accessControl: {
            list: [ROLES.ROLE_ADMIN],
            create: [ROLES.ROLE_ADMIN],
            edit: [ROLES.ROLE_ADMIN],
            delete: [ROLES.ROLE_ADMIN],
        },
    },
    {
        resource: ArticleAuthor,
        accessControl: {
            list: [ROLES.ROLE_ADMIN],
            create: [ROLES.ROLE_ADMIN],
            edit: [ROLES.ROLE_ADMIN],
            delete: [ROLES.ROLE_ADMIN],
        },
    },
    {
        resource: Ingredient,
        accessControl: {
            list: [ROLES.ROLE_ADMIN],
            create: [ROLES.ROLE_ADMIN],
            edit: [ROLES.ROLE_ADMIN],
            delete: [ROLES.ROLE_ADMIN],
        },
    },
    {
        resource: IngredientCategory,
        accessControl: {
            list: [ROLES.ROLE_ADMIN],
            create: [ROLES.ROLE_ADMIN],
            edit: [ROLES.ROLE_ADMIN],
            delete: [ROLES.ROLE_ADMIN],
        },
    },
    {
        resource: Recipe,
        accessControl: {
            list: [ROLES.ROLE_ADMIN],
            create: [ROLES.ROLE_ADMIN],
            edit: [ROLES.ROLE_ADMIN],
            delete: [ROLES.ROLE_ADMIN],
        },
        //detailScreen: RecipeScreen,
    },
    {
        resource: RecipeCategory,
        accessControl: {
            list: [ROLES.ROLE_ADMIN],
            create: [ROLES.ROLE_ADMIN],
            edit: [ROLES.ROLE_ADMIN],
            delete: [ROLES.ROLE_ADMIN],
        },
    },
    {
        resource: RecipeLevel,
        accessControl: {
            list: [ROLES.ROLE_ADMIN],
            create: [ROLES.ROLE_ADMIN],
            edit: [ROLES.ROLE_ADMIN],
            delete: [ROLES.ROLE_ADMIN],
        },
    },
    {
        resource: Season,
        accessControl: {
            list: [ROLES.ROLE_ADMIN],
            create: [ROLES.ROLE_ADMIN],
            edit: [ROLES.ROLE_ADMIN],
            delete: [ROLES.ROLE_ADMIN],
        },
    },
    {
        resource: User,
        accessControl: {
            list: [ROLES.ROLE_ADMIN],
            create: [ROLES.ROLE_ADMIN],
            edit: [ROLES.ROLE_ADMIN],
            delete: [ROLES.ROLE_ADMIN],
        },
        detailScreen: UserEditScreen,
    },
    {
        resource: DietPlan,
        accessControl: {
            list: [ROLES.ROLE_ADMIN],
            create: [ROLES.ROLE_ADMIN],
            edit: [ROLES.ROLE_ADMIN],
            delete: [ROLES.ROLE_ADMIN],
        },
    },
    {
        resource: Objective,
        accessControl: {
            list: [ROLES.ROLE_ADMIN],
            create: [ROLES.ROLE_ADMIN],
            edit: [ROLES.ROLE_ADMIN],
            delete: [ROLES.ROLE_ADMIN],
        },
    },
    {
        resource: DietType,
        accessControl: {
            list: [ROLES.ROLE_ADMIN],
            create: [ROLES.ROLE_ADMIN],
            edit: [ROLES.ROLE_ADMIN],
            delete: [ROLES.ROLE_ADMIN],
        },
    },
    // {
    //     resource: Menu,
    // },
    {
        resource: Meal,
        accessControl: {
            list: [ROLES.ROLE_ADMIN],
            create: [ROLES.ROLE_ADMIN],
            edit: [ROLES.ROLE_ADMIN],
            delete: [ROLES.ROLE_ADMIN],
        },
    },
    {
        resource: CommentRecipe,
        accessControl: {
            list: [ROLES.ROLE_ADMIN],
            create: [ROLES.ROLE_ADMIN],
            edit: [ROLES.ROLE_ADMIN],
            delete: [ROLES.ROLE_ADMIN],
        },
    },
    {
        resource: EventRecipe,
        accessControl: {
            list: [ROLES.ROLE_ADMIN],
            create: [ROLES.ROLE_ADMIN],
            edit: [ROLES.ROLE_ADMIN],
            delete: [ROLES.ROLE_ADMIN],
        },
    },
    {
        resource: PromoCode,
        accessControl: {
            list: [ROLES.ROLE_ADMIN],
            create: [ROLES.ROLE_ADMIN],
            edit: [ROLES.ROLE_ADMIN],
            delete: [ROLES.ROLE_ADMIN],
        },
    },
    {
        resource: PromotionalCommunication,
        accessControl: {
            list: [ROLES.ROLE_ADMIN],
            create: [ROLES.ROLE_ADMIN],
            edit: [ROLES.ROLE_ADMIN],
            delete: [ROLES.ROLE_ADMIN],
        },
    },
    {
        resource: PromoCodeGroup,
        accessControl: {
            list: [ROLES.ROLE_ADMIN],
            create: [ROLES.ROLE_ADMIN],
            edit: [ROLES.ROLE_ADMIN],
            delete: [ROLES.ROLE_ADMIN],
        },
    },
    {
        resource: PromotionalMedia,
        accessControl: {
            list: [ROLES.ROLE_ADMIN],
            create: [ROLES.ROLE_ADMIN],
            edit: [ROLES.ROLE_ADMIN],
            delete: [ROLES.ROLE_ADMIN],
        },
    },
    {
        resource: PromotionalNewsletter,
        accessControl: {
            list: [ROLES.ROLE_ADMIN],
            create: [ROLES.ROLE_ADMIN],
            edit: [ROLES.ROLE_ADMIN],
            delete: [ROLES.ROLE_ADMIN],
        },
    },
    {
        resource: PrivateFooterBanner,
        accessControl: {
            list: [ROLES.ROLE_ADMIN],
            create: [ROLES.ROLE_ADMIN],
            edit: [ROLES.ROLE_ADMIN],
            delete: [ROLES.ROLE_ADMIN],
        },
    },
    {
        resource: Review,
        accessControl: {
            list: [ROLES.ROLE_ADMIN],
            create: [ROLES.ROLE_ADMIN],
            edit: [ROLES.ROLE_ADMIN],
            delete: [ROLES.ROLE_ADMIN],
        },
    },
    {
        resource: PrivatePromoBlock,
        accessControl: {
            list: [ROLES.ROLE_ADMIN],
            create: [ROLES.ROLE_ADMIN],
            edit: [ROLES.ROLE_ADMIN],
            delete: [ROLES.ROLE_ADMIN],
        },
    },
    {
        resource: RecipeAutoPromo,
        accessControl: {
            list: [ROLES.ROLE_ADMIN],
            create: [ROLES.ROLE_ADMIN],
            edit: [ROLES.ROLE_ADMIN],
            delete: [ROLES.ROLE_ADMIN],
        },
    },
    {
        resource: PrivatePromoPopin,
        accessControl: {
            list: [ROLES.ROLE_ADMIN],
            create: [ROLES.ROLE_ADMIN],
            edit: [ROLES.ROLE_ADMIN],
            delete: [ROLES.ROLE_ADMIN],
        },
    },
    {
        resource: RecipePublicCategory,
        accessControl: {
            list: [ROLES.ROLE_ADMIN],
            create: [ROLES.ROLE_ADMIN],
            edit: [ROLES.ROLE_ADMIN],
            delete: [ROLES.ROLE_ADMIN],
        },
    },
    {
        resource: BodyParts,
        accessControl: {
            list: [ROLES.ROLE_ADMIN],
            create: [ROLES.ROLE_ADMIN],
            edit: [ROLES.ROLE_ADMIN],
            delete: [ROLES.ROLE_ADMIN],
        },
    },
    {
        resource: Tag,
        accessControl: {
            list: [ROLES.ROLE_ADMIN],
            create: [ROLES.ROLE_ADMIN],
            edit: [ROLES.ROLE_ADMIN],
            delete: [ROLES.ROLE_ADMIN],
        },
    },
    {
        resource: PublicPromoPopin,
        accessControl: {
            list: [ROLES.ROLE_ADMIN],
            create: [ROLES.ROLE_ADMIN],
            edit: [ROLES.ROLE_ADMIN],
            delete: [ROLES.ROLE_ADMIN],
        },
    },
    {
        resource: Feature,
        accessControl: {
            list: [ROLES.ROLE_ADMIN],
            create: [ROLES.ROLE_ADMIN],
            edit: [ROLES.ROLE_ADMIN],
            delete: [ROLES.ROLE_ADMIN],
        },
    },
];
export default screens;
